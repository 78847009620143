import React,{ useRef } from "react";


const AutoExpandIFrame = ({ style, title, src }) => {
    const ref = useRef(null);
    return <iframe
      style={{
        border: 0, padding: 0, margin: 0, width: '100%', overflow: 'hidden',
        ...style,
      }}
      title={title} ref={ref} src={src}
      onLoad={() => {
          const iframe = ref.current;
          const observer = new ResizeObserver((entries) => {
            iframe.style.height = entries[0].target.scrollHeight + 'px';
          });
          const script = document.createElement('script');
          script.innerHTML = 'document.body.style.overflow = "hidden";';
          iframe.contentDocument.body.appendChild(script);
          observer.observe(iframe.contentWindow.document.body);
      }}
    />;
};

export default AutoExpandIFrame;

